import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

export type IconProps = React.HTMLAttributes<HTMLSpanElement> & {
  sx?: React.CSSProperties;
  boundingSize?: string;
  textSize?: string;
  type: string;
  name: string;
  color?: string;
};

type IconPrivateContainerProps = {
  sx: React.CSSProperties | undefined;
};

type IconPrivateProps = {
  boundingSize?: string;
  textSize?: string;
  color?: string;
};

const IconPrivateContainer = styled('span')<IconPrivateContainerProps>(
  ({ sx }) => ({
    ...sx,
    display: 'inline-block',
  }),
);

const IconPrivate = styled('i')<IconPrivateProps>(
  ({ boundingSize, textSize, color, theme }) => ({
    fontSize: textSize,
    height: boundingSize,
    width: boundingSize,
    color: color ? (theme.palette as any)[color].main : 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

const Icon = React.forwardRef<HTMLSpanElement, IconProps>(
  (
    { sx, type, name, color, textSize, boundingSize, ...props }: IconProps,
    ref,
  ) => (
    <IconPrivateContainer ref={ref} sx={sx} {...props}>
      <IconPrivate
        className={`${type} ${name}`}
        textSize={textSize}
        boundingSize={boundingSize}
        color={color}
      />
    </IconPrivateContainer>
  ),
);

Icon.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.func]),
  boundingSize: PropTypes.string,
  textSize: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Icon.defaultProps = {
  sx: undefined,
  boundingSize: 'inherit',
  textSize: 'inherit',
  color: undefined,
};

export default Icon;
