import MUIDialogContent, {
  DialogContentProps as MUIDialogContentProps,
} from '@mui/material/DialogContent';
import React from 'react';

export type DialogBodyProps = MUIDialogContentProps;

const DialogBody = React.forwardRef<HTMLDivElement, DialogBodyProps>(
  ({ children, ...props }: DialogBodyProps, ref) => (
    <MUIDialogContent ref={ref} {...props}>
      {children}
    </MUIDialogContent>
  ),
);

export default DialogBody;
