import MUIDialog, { DialogProps as MUIDialogProps } from '@mui/material/Dialog';
import React from 'react';

export type DialogProps = MUIDialogProps;

const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  ({ children, ...props }: DialogProps, ref) => (
    <MUIDialog ref={ref} fullWidth {...props}>
      {children}
    </MUIDialog>
  ),
);

export default Dialog;
