import MUIDialogTitle, {
  DialogTitleProps as MUIDialogTitleProps,
} from '@mui/material/DialogTitle';
import React from 'react';
import Icon from '../Icon';
import IconButton from '../IconButton';

export type DialogHeaderProps = MUIDialogTitleProps;

const DialogHeader = React.forwardRef<HTMLDivElement, DialogHeaderProps>(
  ({ onClick, sx, children, ...props }: DialogHeaderProps, ref) => (
    <MUIDialogTitle
      ref={ref}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...sx,
      }}
      {...props}
    >
      {children}
      <IconButton size="small" color="default" onClick={onClick}>
        <Icon type="fa-solid" name="fa-xmark" textSize="24px" />
      </IconButton>
    </MUIDialogTitle>
  ),
);

export default DialogHeader;
