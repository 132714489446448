import React from 'react';
import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button';

export type ButtonProps = MUIButtonProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <MUIButton ref={ref} {...props} />,
);

export default Button;
