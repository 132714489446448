import MUIIconButton, {
  IconButtonProps as MUIIconButtonProps,
} from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

export type IconButtonProps = MUIIconButtonProps & {
  color?: 'default' | 'primary' | 'error';
  size?: 'small' | 'medium';
};

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props: IconButtonProps, ref) => <MUIIconButton ref={ref} {...props} />,
);

IconButton.propTypes = {
  color: PropTypes.oneOf(['default', 'primary', 'error']),
  size: PropTypes.oneOf(['small', 'medium']),
};

IconButton.defaultProps = {
  color: 'default',
  size: 'medium',
};

export default IconButton;
