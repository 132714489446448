import { styled } from '@mui/material/styles';
import MUITextField, {
  TextFieldProps as DefaultTextFieldProps,
} from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';

export type TextFieldProps =
  | Omit<DefaultTextFieldProps, 'size'> & {
      size?: 'small' | 'medium';
      startAdornment?: React.ReactNode;
      endAdornment?: React.ReactNode;
    };

export const defaultTextFieldProps: TextFieldProps = {
  size: 'medium',
  startAdornment: undefined,
  endAdornment: undefined,
};

const AdornmentContainer = styled('div')(({ theme }) => ({
  fontSize: theme.fontSize.medium,
}));

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      size,
      startAdornment,
      endAdornment,
      variant,
      disabled,
      ...props
    }: TextFieldProps,
    ref,
  ) => (
    <MUITextField
      ref={ref}
      size={size}
      variant={variant}
      disabled={disabled}
      InputProps={{
        startAdornment: startAdornment && (
          <AdornmentContainer className="KatalonUI-start-adornment">
            {startAdornment}
          </AdornmentContainer>
        ),
        endAdornment: endAdornment && (
          <AdornmentContainer className="KatalonUI-end-adornment">
            {endAdornment}
          </AdornmentContainer>
        ),
      }}
      {...props}
    />
  ),
);

TextField.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
};

TextField.defaultProps = defaultTextFieldProps;

export default TextField;
