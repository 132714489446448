import MUIDialogActions, {
  DialogActionsProps as MUIDialogActionsProps,
} from '@mui/material/DialogActions';
import React from 'react';

export type DialogFooterProps = MUIDialogActionsProps;

const DialogFooter = React.forwardRef<HTMLDivElement, DialogFooterProps>(
  ({ children, ...props }: DialogFooterProps, ref) => (
    <MUIDialogActions ref={ref} {...props}>
      {children}
    </MUIDialogActions>
  ),
);

export default DialogFooter;
